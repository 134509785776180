<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Merchant"
              label-for="merchant"
            >
              <v-select
                v-model="filters['merchant_id']"
                :options="merchantOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Merchant"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <ReportTableButtons
                    :items="rows"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchWaybillRequestListNoPagination"
                    @refresh="fetchWaybillRequestList"
                  />
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              :fields="fields"
              :items="rows"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <!-- Custom template for the empty cells -->
              <template #cell()="data">
                {{ data.value || '---' }}
              </template>
              <!-- Proof of Delivery Custom template for the cells -->
              <template #cell(requested_proof_of_delivery_count)="data">
                <div class="custom-width">
                  {{ __numberWithCommas(data.value) || 0 }}
                </div>
              </template>
              <!-- No Of Waybills Custom template for the cells -->
              <template #cell(requested_waybill_count)="data">
                {{ __numberWithCommas(data.value) || 0 }}
              </template>
              <!-- Issued Proof of Delivery Custom template for the cells -->
              <template #cell(issued_proof_of_delivery_count)="data">
                <div class="custom-width">
                  {{ __numberWithCommas(data.value) }}
                </div>
              </template>
              <!-- Issued waybill Custom template for the cells  -->
              <template #cell(issued_waybill_count)="data">
                {{ __numberWithCommas(data.value) }}
              </template>
              <!-- status badge beautify -->
              <template #cell(req_status)="data">
                <b-badge :variant="data.value === 'confirmed' ? 'light-success' : data.value === 'pending' ? 'light-warning' : 'light-danger'">
                  {{ data.value === 'confirmed' ? 'Confirmed' : (data.value === 'pending' ? 'Pending' : 'Rejected') }}
                </b-badge>
              </template>
              <!-- client_name beautify -->
              <!-- <template #cell(client_name)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.client_name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.item.client_name }}
                    </div>
                  </div>
                </div>
              </template> -->
              <!-- request_date beautify -->
              <template #cell(request_date)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <!-- confirm_date beautify -->
              <template #cell(confirm_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>

              <template #cell(action)="data">
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="12"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'reason-show'"
                    @click="onClickUpdate(data.item.id)"
                  >
                    <feather-icon icon="ToolIcon" />
                    Manage Request
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'reason-show'"
                    @click="onRejectRequest(data.item.id)"
                  >
                    <feather-icon icon="XIcon" />
                    Reject Request
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    class="per-page-selector d-inline-block ml-1 width-125"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  cols="12"
                  md="9"
                >
                  <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>

    <set-waybillRequest ref="set_waybillRequest" />

  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCol,
  BFormGroup,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import store from '@/store'
import SetWaybillRequest from '@/components/waybill-requests/SetWaybillRequestModal.vue'
import getMerchantList from '../reports/ReturnOrderMerchant/MerchantListDropdown'

const waybillRequestRepository = RepositoryFactory.get('waybill')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    BFormGroup,
    BCard,
    BBadge,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BPagination,
    SetWaybillRequest,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      merchantOptions: [],
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      dir: false,
      recordId: 0,
      fields: [
        {
          key: 'id',
          label: '#',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'client_name',
          label: 'Merchant',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'request_date',
          label: 'Request Date',
          sortable: true,
        },
        {
          key: 'requested_proof_of_delivery_count',
          label: 'Proof of Delivery',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'requested_waybill_count',
          label: 'No Of Waybills',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'from_waybill',
          label: 'From Waybill No',
          sortable: true,
        },
        {
          key: 'to_waybill',
          label: ' To Waybill No',
          sortable: true,
        },
        {
          key: 'confirm_date',
          label: 'Confirm Date',
          sortable: true,
        },
        {
          key: 'issued_proof_of_delivery_count',
          label: 'Issued Proof of Delivery',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'issued_waybill_count',
          label: 'Issued Waybills',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'req_status',
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      downloadReportName: 'Waybill-Request-Report',
      json_fields: {
        Merchant: {
          field: 'client_name',
          callback: value => `"${value}"`,
        },
        'Request Date': {
          field: 'request_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Proof of Delivery': {
          field: 'requested_proof_of_delivery_count',
          callback: value => `"${value}"`,
        },
        'No Of Waybills': {
          field: 'requested_waybill_count',
          callback: value => `"${value}"`,
        },
        'From Waybill No': {
          field: 'from_waybill',
          callback: value => `"${value}"`,
        },
        'To Waybill No': {
          field: 'to_waybill',
          callback: value => `"${value}"`,
        },
        'Confirm Date': {
          field: 'confirm_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Issued Proof of Delivery': {
          field: 'issued_proof_of_delivery_count',
          callback: value => `"${value}"`,
        },
        'Issued Waybills': {
          field: 'issued_waybill_count',
          callback: value => `"${value}"`,
        },
        Status: {
          field: 'req_status',
          callback: value => `"${value}"`,
        },
      },
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    page() {
      this.fetchWaybillRequestList()
    },
    perPage() {
      this.fetchWaybillRequestList()
    },
  },
  mounted() {
    // Set the initial number of items
    this.MerchantList()
    this.fetchWaybillRequestList()
  },
  methods: {
    async onRejectRequest(id) {
      try {
        const payload = {} // You can provide any payload data if required
        const res = await waybillRequestRepository.rejectWaybillRequest(id, payload)

        if (res.status === 200 || res.status === 201) {
          this.showSuccessMessage('Waybill Request Rejected')
          this.open = false
          this.onClickRefresh()
        } else {
          this.showErrorMessage(res.data.message)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async MerchantList() {
      this.merchantOptions = await getMerchantList()
    },
    async fetchWaybillRequestList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await waybillRequestRepository.getWaybillRequestList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.showErrorMessage(e)
      }
      this.dataLoading = false
    },
    async fetchWaybillRequestListNoPagination() {
      try {
        const { data } = (await waybillRequestRepository.getWaybillRequestListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.showErrorMessage(e)
        return []
      }
    },
    onClickUpdate(id) {
      this.$refs.set_waybillRequest.openModal(id, 'edit')
    },
    applyFilters() {
      this.fetchWaybillRequestList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchWaybillRequestList()
      })
    },
    onClickReject(id) {
      this.$refs.set_waybillRequest.openModal(id, 'edit')
    },
    handleChangePage(page) {
      this.page = page
      this.fetchWaybillRequestList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.fetchWaybillRequestList()
    },
  },
}
</script>
<style scoped>
.custom-width {
  width: 120px;
}
</style>
