<template>
  <div>
    <b-modal
      id="modal-waybillRequest-add"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      :title="modalTitle"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createWaybillRequestForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            autocomplete="off"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Requested Merchant"
                  label-cols-md="4"
                  label-for="merchant_name"
                >
                  <b-form-input
                    id="merchant_name"
                    v-model="form.merchant.name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Requested Date"
                  label-cols-md="4"
                  label-for="created_date"
                >
                  <b-form-input
                    id="created_date"
                    :value="__dateTimeFormatter(form.created_at)"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Requested Proof of Delivery"
                  label-cols-md="4"
                  label-for="proof_of_delivery"
                >
                  <b-form-input
                    id="proof_of_delivery"
                    v-model="form.requested_proof_of_delivery_count"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Requested No of Waybills"
                  label-cols-md="4"
                  label-for="no_waybills"
                >
                  <b-form-input
                    id="no_waybills"
                    v-model="form.requested_waybill_count"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="From Waybill"
                  label-cols-md="4"
                  label-for="confirm-waybill"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="From Waybill"
                    rules="required"
                  >
                    <b-form-input
                      id="from_waybill"
                      v-model="form.from_waybill"
                      :disabled="isNotPending"
                      :placeholder="isNotPending ? '' : 'Enter From Waybill Number'"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="To Waybill"
                  label-cols-md="4"
                  label-for="confirm-waybill"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="To Waybill"
                  >
                    <b-form-input
                      id="to_waybill"
                      v-model="form.to_waybill"
                      :disabled="isNotPending"
                      :placeholder="isNotPending ? '' : 'Enter To Waybill Number'"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Issued Proof of Delivery"
                  label-cols-md="4"
                  label-for="confirm-waybill"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Issued Proof of Delivery"
                    rules="required"
                  >
                    <b-form-input
                      id="issued_proof_of_delivery_count"
                      v-model="form.issued_proof_of_delivery_count"
                      :disabled="isNotPending"
                      :placeholder="isNotPending ? '' : 'No of Proof of Delivery Issued'"
                      type="number"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Issued No of Waybills"
                  label-cols-md="4"
                  label-for="confirm-waybill"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Issued Waybills"
                    rules="required"
                  >
                    <b-form-input
                      id="issued_waybill_count"
                      v-model="form.issued_waybill_count"
                      :disabled="isNotPending"
                      :placeholder="isNotPending ? '' : 'No of Waybills Issued'"
                      type="number"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                v-if="!isNotPending"
                offset-md="4"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="clearAll"
                  >
                    Clear
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  VBModal,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const waybillRequestRepository = RepositoryFactory.get('waybill')

function initialState() {
  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    form: {},
    statusOptions: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? 'ADD NEW WAYBILL REQUEST' : 'UPDATE WAYBILL REQUEST'
    },
    isNotPending() {
      return this.form.waybill_request_status === 'confirmed' || this.form.waybill_request_status === 'rejected'
    },
  },
  methods: {
    async openModal(id, purpose) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      await this.fetchWaybillRequestDetails(this.id)
      // }
      this.loading = false
    },
    async fetchWaybillRequestDetails(id) {
      try {
        const { data } = (await waybillRequestRepository.getWaybillRequestDetailsbyID(id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleFormSubmit() {
      try {
        this.loading = true
        const payload = {
          from_waybill: this.form.from_waybill,
          to_waybill: this.form.to_waybill,
          issued_proof_of_delivery_count: this.form.issued_proof_of_delivery_count,
          issued_waybill_count: this.form.issued_waybill_count,
          // Add other properties as needed based on your API requirements
        }
        const res = await waybillRequestRepository.updateWaybillRequest(this.id, payload)
        if (res.status === 200 || res.status === 201) {
          this.showSuccessMessage('Waybill Request Confirmed')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage(res.data.message)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearAll() {
      this.form = {}
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
